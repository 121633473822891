import React from "react";

const TeamChart = ({ title, children, name, avatar }) => (
	<li>
		<div className="bg-[#A67FFB] team-chart card border">
			<span className="label flex justify-center team-chart-name mb-2">
				{name}
			</span>
			<img src={avatar} alt="images" className="mx-auto mb-2" />
			<span className="bg-[#A67FFB] py-1 team-chart-title px-2 text-xs text-white uppercase font-semibold">
				{title}
			</span>
		</div>
		{children && <ul>{children}</ul>}
	</li>
);

export default TeamChart;
