import React, { useState, useContext, useEffect } from "react";
import {
	InputBox,
	Loader,
	Table,
	Dropdown,
	Popup,
	FormRow,
	Button,
} from "../../components";
import {
	PayslipFormBodyData,
	PayslipFormHeadData,
	dropDownPagination,
} from "../../data";
import ApiCall from "../../utils/ApiCall";
import { PAYROLLPAYSLIPHR, PAYROLLPAYSLIPUAPDATE } from "../../Constants";
import AuthContext from "../../contexts/AuthContext";
import SweetAlert from "../../utils/SweetAlert";

const PayslipUpdate = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [payslipUpdation, setPayslipUpdation] = useState([]);
	const [popData, setPopData] = useState(null);
	const [searchData, setSearchData] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState(10);
	const [resId, setResId] = useState(0);
	const rowPerPage = 5;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem(
			"payslip-updationmain-pagination-data"
		);
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${PAYROLLPAYSLIPHR}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			// console.log("res", res.data);
			setPayslipUpdation(res.data);
			setIsLoading(false);
		} catch (error) {
			console.log("Error While Fetching Data", error);
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"payslip-updationmain-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handleSearch = (e) => {
		setSearchData(e.target.value);
	};

	const searchedData =
		payslipUpdation &&
		payslipUpdation.filter((item) =>
			item.email.toLowerCase().includes(searchData.toLowerCase())
		);

	const handlePayslipUpdateID = async (rowIndex) => {
		let id;
		if (searchedData.length > 7) {
			const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
			id = searchedData[actualIndex].id;
		} else {
			const correctIndex = payslipUpdation.findIndex(
				(item) => item.id === searchedData[rowIndex].id
			);
			id = payslipUpdation[correctIndex].id;
		}
		//console.log("id", id);
		//console.log("id", id);

		try {
			const res = await ApiCall(
				`${PAYROLLPAYSLIPUAPDATE}?id=${id}`,
				"GET",
				authTokens,
				null
			);
			//console.log("res", res.data.payslipdetails[0]);
			setPopData(res.data.payslipdetails[0]);
		} catch (error) {
			console.log("Error While Getting Data", error);
		}
	};

	const handlePopupDataSubmit = async (e) => {
		e.preventDefault();
		const params = {
			ctc: popData.sal_ctc_pa,
			emp_id: popData.user_id,
			base_salary: popData.basic_salary,
			hra_allowance: popData.hra_allowance,
			spl_allowance: popData.spl_allowance,
			total_earnings: popData.total_earnings,
			employee_provident_fund: popData.employee_provident_fund,
			esi: popData.esi,
			tds: popData.tds,
			total_deduction: popData.total_deduction,
			sal_ctc_pm: popData.sal_ctc_pm,
			gross_salary: popData.gross_salary,
			net_salary: popData.net_salary,
			arrear_amount: popData.arrear_amount,
			lop: popData.lop,
			lop_days: popData.lop_days,
			no_of_workingdays: popData.no_of_workingdays,
			paid_days: popData.paid_days,
			variable_pay: popData.variable_pay,
			professional_tax: popData.professional_tax,
		};
		//console.log("paramas", params);
		setPopData(null);
		try {
			const res = await ApiCall(
				PAYROLLPAYSLIPUAPDATE,
				"POST",
				authTokens,
				params
			);
			// console.log("res", res);
			if (res.status === 200) {
				SweetAlert({ title: "Successfully Updated", icon: "success" });
			} else {
				SweetAlert({ title: "Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("Error While Sending Data", error);
		}
		fetchData();
	};

	const handleChange = (e) => {
		setPopData({ ...popData, [e.target.name]: e.target.value });
	};

	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Pay Slips Updation
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center intro-x">
					<Loader />
				</div>
			) : (
				<>
					<div className="p-2 flex justify-between w-full mt-4 intro-x">
						<InputBox
							value={searchData}
							onChange={handleSearch}
							placeholder="Search"
							type="search"
						/>
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					<Table
						tableStyle="border-spacing-y-[10px] border-separate -mt-4"
						bodyStyle="bg-accent box-shadow"
						header={PayslipFormHeadData}
						body={searchedData.map((row) => [
							row.email,
							row.emp,
							row.designation,
							row.ctc,
						])}
						btnstyle="intro-x"
						bodyTdStyle="table-body-td intro-x"
						headTdStyle="table-body-td intro-x"
						btnTitle="Update"
						paginationNeed={searchedData.length > 7 ? true : false}
						currentPage={currentPage}
						rowPerPage={rowPerPage}
						paginationBody={payslipUpdation}
						onPageChange={handlePageChange}
						onClick={handlePayslipUpdateID}
					/>
					{popData && (
						<Popup
							title="Pay Slip Updation"
							trigger={popData}
							setTrigger={setPopData}
							popupstyle="md:w-[500px] w-full"
						>
							<form className="my-4" onSubmit={handlePopupDataSubmit}>
								<FormRow
									label="Employee Name"
									item={
										<InputBox
											disabled={true}
											type="text"
											name="name"
											value={popData.name}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="CTC - Per Annum"
									item={
										<InputBox
											disabled={true}
											type="number"
											name="sal_ctc_pa"
											value={popData.sal_ctc_pa}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Basic Salary + DA"
									item={
										<InputBox
											type="number"
											name="basic_salary"
											value={popData.basic_salary}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="HRA Allowance"
									item={
										<InputBox
											type="number"
											name="hra_allowance"
											value={popData.hra_allowance}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Special Allowance"
									item={
										<InputBox
											type="number"
											name="spl_allowance"
											value={popData.spl_allowance}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Total Earnings"
									item={
										<InputBox
											type="number"
											name="total_earnings"
											value={popData.total_earnings}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="EPF"
									item={
										<InputBox
											type="number"
											name="employee_provident_fund"
											value={popData.employee_provident_fund}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="ESI"
									item={
										<InputBox
											type="number"
											name="esi"
											value={popData.esi}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="TDS"
									item={
										<InputBox
											type="number"
											name="tds"
											value={popData.tds}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Variable Pay"
									item={
										<InputBox
											type="number"
											name="variable_pay"
											value={popData.variable_pay}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Professional Tax"
									item={
										<InputBox
											type="number"
											name="professional_tax"
											value={popData.professional_tax}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="LOP"
									item={
										<InputBox
											type="number"
											name="lop"
											value={popData.lop}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="LOP Days"
									item={
										<InputBox
											type="number"
											name="lop_days"
											value={popData.lop_days}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Total Working Days"
									item={
										<InputBox
											type="number"
											name="no_of_workingdays"
											value={popData.no_of_workingdays}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Paid Days"
									item={
										<InputBox
											type="number"
											name="paid_days"
											value={popData.paid_days}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Total Deductions"
									item={
										<InputBox
											type="number"
											name="total_deduction"
											value={popData.total_deduction}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Salary (CTC) /PM"
									item={
										<InputBox
											type="number"
											name="sal_ctc_pm"
											value={popData.sal_ctc_pm}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Gross Salary"
									item={
										<InputBox
											type="number"
											name="gross_salary"
											value={popData.gross_salary}
											onChange={handleChange}
										/>
									}
								/>
								<FormRow
									label="Net Salary"
									item={
										<InputBox
											type="number"
											name="net_salary"
											value={popData.net_salary}
											onChange={handleChange}
										/>
									}
									/>
								<FormRow
									label="Arrear Amount"
									item={
										<InputBox
											type="number"
											name="arrear_amount"
											value={popData.arrear_amount}
											onChange={handleChange}
										/>
									}
								/>
								<div className="w-full flex justify-center my-2">
									<Button classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm">
										Submit
									</Button>
								</div>
							</form>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default PayslipUpdate;
