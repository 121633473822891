import React, { useContext } from "react";
import Layout from "../components/Layout";
import { Sidebar } from "../components";
import EmpDetails from "../sub-pages/EmployeeDetailsPage/EmpDetails";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import { EmployeeDetailsMang } from "../data";

const EmployeeDetails = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<>
			{user.user_role === "MANAGER" ? (
				<Layout sidebar={<Sidebar sidebarLinks={EmployeeDetailsMang} />}>
					<Route path="/employee-details" component={EmpDetails} />
				</Layout>
			) : null}
		</>
	);
};

export default EmployeeDetails;
