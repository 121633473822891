import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { EmpDetailsForMangInfo } from "../../Constants";
import { Table } from "../../components";
import { empDetailsTabHeadInfo } from "../../data";
import { Loader } from "../../components";

const EmpDetails = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [empInfoData, setEmpInfoData] = useState({ userlist: [] });
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 8;

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(EmpDetailsForMangInfo, "GET", authTokens, null);
			//console.log("res", res);
			setEmpInfoData(res.data);
			// console.log("holiday", res.data.holiday);
			setIsLoading(false);
		} catch (error) {
			console.log("error during Emp Details For Mang data fetching", error);
			setIsLoading(false);
		}
	};

	const newEmpInfoData = empInfoData.userlist || [];

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-bold uppercase">
				Employee Details
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<Table
					tableStyle="border-spacing-y-[10px] border-separate -mt-4"
					bodyStyle="bg-accent box-shadow"
					header={empDetailsTabHeadInfo}
					body={newEmpInfoData.map((row) => [
						row.name,
						row.per_phone_number === null ? "-" : row.per_phone_number,
						row.tem_phone_number === null ? "-" : row.tem_phone_number,
						row.per_address1 === null ? "-" : row.per_address1,
						row.tem_address1 === null ? "-" : row.tem_address1,
					])}
					bodyTdStyle="table-body-td intro-x"
					headTdStyle="table-body-td intro-x"
					paginationNeed={true}
					currentPage={currentPage}
					rowPerPage={rowPerPage}
					paginationBody={newEmpInfoData}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default EmpDetails;
