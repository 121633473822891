import React, { useState, useContext, useEffect } from "react";
import Logo from "../../images/logo.png";
import {
	Button,
	Dropdown,
	PaySlipRow,
	ProfileLabel,
	Table,
} from "../../components";
import { filterDateMonth } from "../../data";
import "../../css/payslip.css";
import { PAYROLLPAYSLIP } from "../../Constants";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Payslips = () => {
	const { authTokens } = useContext(AuthContext);
	const date = new Date();
	const currentYear = date.getFullYear();
	const lastYear = currentYear - 1;
	const twoYearsBack = lastYear - 1;
	const [payslipData, setPayslipData] = useState([]);

	const filterDataYear = [
		{ value: "none", label: "Select" },
		{
			value: currentYear,
			label: currentYear,
		},
		{
			value: lastYear,
			label: lastYear,
		},
		{
			value: twoYearsBack,
			label: twoYearsBack,
		},
	];

	const [year, setYear] = useState(
		sessionStorage.getItem("year")
			? JSON.parse(sessionStorage.getItem("year"))
			: ""
	);
	const [month, setMonth] = useState(
		sessionStorage.getItem("month")
			? JSON.parse(sessionStorage.getItem("month"))
			: ""
	);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${PAYROLLPAYSLIP}?year=${year}&month=${month}`,
				"GET",
				authTokens,
				null
			);
			//console.log("res", res.data);
			setPayslipData(res.data);
		} catch (error) {
			console.log("While fetching User Data", error);
		}
	};

	const handleYear = (e) => {
		e.preventDefault();
		setYear(e.target.value);
		sessionStorage.setItem("year", JSON.stringify(e.target.value));
	};

	const handleMonth = (e) => {
		e.preventDefault();
		setMonth(e.target.value);
		sessionStorage.setItem("month", JSON.stringify(e.target.value));
	};

	const handlePayslipFilter = async (e) => {
		e.preventDefault();
		try {
			const res = await ApiCall(
				`${PAYROLLPAYSLIP}?year=${year}&month=${month}`,
				"GET",
				authTokens,
				null
			);
			//console.log("res", res.data);
			setPayslipData(res.data);
		} catch (error) {
			console.log("While fetching User Data", error);
		}
	};
	const handleDownloadPayslip = () => {
		const mainPdfDiv = document.getElementById("mainPdfDiv");
		setTimeout(() => {
			html2canvas(mainPdfDiv, { scale: 1.5 }).then((canvas) => {
				const imgData = canvas.toDataURL("image/png", 0.6);
				const pdf = new jsPDF({
					unit: "px",
					format: "a4",
				});
				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
				pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
				pdf.save("payslip.pdf");
			});
		}, 500);
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold mb-4">
				Pay Slips
			</h2>
			<div className="flex md:flex-row flex-col gap-4 justify-between items-center px-4">
				<form
					onSubmit={handlePayslipFilter}
					className="flex md:flex-row flex-col md:gap-2 gap-1 intro-x items-center"
				>
					<ProfileLabel lableStyle="text-sm" label="Year" />
					<Dropdown
						onChange={handleYear}
						value={year}
						options={filterDataYear}
					/>
					<ProfileLabel lableStyle="text-sm" label="Month" />
					<Dropdown
						onChange={handleMonth}
						value={month}
						options={filterDateMonth}
					/>
					<Button classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm mx-4">
						Filter
					</Button>
				</form>
				<div className="intro-x">
					<Button
						onClick={handleDownloadPayslip}
						classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
					>
						Download PDF
					</Button>
				</div>
			</div>
			<div className="intro-x">
				<div
					id="mainPdfDiv"
					className="box-shadow bg-accent transition flex flex-col items-center justify-center w-full mt-4 pb-4"
				>
					<div className="w-full p-4">
						<h3 className="text-center text-md md:text-lg font-bold nav-item-active ">
							PAYSLIP FOR THE MONTH OF {payslipData.month} - {payslipData.year}
						</h3>
						<div className="flex md:flex-row flex-col items-center md:justify-between ">
							<div className="flex md:items-start items-center flex-col">
								<img className="md:w-56 w-44" src={Logo} alt="Prowesstics" />
								<p className="px-2 font-bold">
									Prowesstics IT Services Pvt.Ltd
								</p>
								<p className="px-2 w-56 text-center md:text-start">
									Building No.110, 3rd Floor, Ampa Manor, Nelson Manickam Road,
									Chennai (600006).
								</p>
							</div>
							<div className="py-4 px-2 mt-6 items-center">
								<p className="text-lg px-2 font-bold">
									PAYSLIP #{payslipData.id}
								</p>
								<p className="px-2">
									Salary Month: {payslipData.month}, {payslipData.year}
								</p>
							</div>
						</div>
						<div className="flex md:flex-row flex-col items-center md:justify-between my-6 ">
							<div className="flex md:items-start flex-col">
								<p className="px-2 font-bold">{payslipData.employee_name}</p>
								<p className="px-2">{payslipData.employee_designation}</p>
								<PaySlipRow
									dataone="Employee Id"
									dataTwo={payslipData.employee_id}
								/>
								<PaySlipRow dataone="PF Number" dataTwo={payslipData.pf_no} />
								<PaySlipRow
									dataone="Bank Account No"
									dataTwo={payslipData.acc_no}
								/>
							</div>
							<div className="py-4 px-2 items-center md:items-start">
								<PaySlipRow
									dataone="Total Working Days"
									dataTwo={payslipData.no_of_workingdays}
								/>
								<PaySlipRow
									dataone="Paid Days"
									dataTwo={payslipData.paid_days}
								/>
								<PaySlipRow dataone="LOP Days" dataTwo={payslipData.lop_days} />
							</div>
						</div>
						<div className="flex flex-col justify-center gap-4 px-2 w-full">
							<div className="w-full">
								<p className="font-bold text-md">Earnings</p>
								<table className="w-full table-fixed">
									<thead className="w-full">
										<tr className="text-start hover:none ">
											<th className="border border-indigo-500/50 text-sm py-2 px-1 uppercase font-bold text-start">
												Pay Head
											</th>
											<th className="border border-indigo-500/50 text-sm py-2 px-1 uppercase font-bold ">
												Amount
											</th>
											<th className="border border-indigo-500/50 text-sm py-2 px-1 uppercase font-bold text-end">
												YTD
											</th>
										</tr>
									</thead>
									<tbody className="w-full text-center">
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												Basic Salary +DA
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.m_basic_salary}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.basic_salary}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												HRA Allowance
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.m_hra_allowance}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.hra_allowance}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												Spl Allowance
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.m_spl_allowance}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.spl_allowance}
											</td>
										</tr>
										{payslipData.arrear_amount === 0 ? null : (
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												Arrear Amount
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.arrear_amount}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												-
											</td>
										</tr>
										)}
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 font-bold text-start">
												Total Earnings
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 font-bold">
												₹ {payslipData.m_total_earnings}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 font-bold text-end">
												₹ {payslipData.total_earnings}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="w-full">
								<p className="font-bold text-md">Deductions</p>
								<table className="w-full table-fixed">
									<thead className="w-full text-start">
										<tr className="">
											<th className="border border-indigo-500/50 text-sm py-2 px-1 uppercase font-bold text-start">
												Pay Head
											</th>
											<th className="border border-indigo-500/50 text-sm py-2 px-1 uppercase font-bold ">
												Amount
											</th>
											<th className="border border-indigo-500/50 text-sm py-2 px-1 uppercase font-bold text-end">
												YTD
											</th>
										</tr>
									</thead>
									<tbody className="w-full text-center">
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												EPF
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.m_provident_fund}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.employee_provident_fund}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												ESI
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.m_esi}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.esi}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												TDS
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.m_tds}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.tds}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												Variable Pay
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.variable_pay}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.variable_pay}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												LOP
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.lop}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.lop}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-start">
												Professional Tax
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1">
												₹ {payslipData.professional_tax}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 text-end">
												₹ {payslipData.y_professional_tax}
											</td>
										</tr>
										<tr className="payslip ">
											<td className="border border-indigo-500/50 text-sm py-2 px-1 font-bold text-start">
												Total Deductions
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 font-bold">
												₹ {payslipData.m_total_deduction}
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 font-bold text-end">
												₹ {payslipData.total_deduction}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="flex flex-col w-full">
								<table className="w-full table-fixed">
									<tbody className="w-full">
										<tr className="payslip ">
											{/* <td className="border border-indigo-500/50 text-sm py-2 px-1 w-full">
												Salary (CTC)/ PM
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 w-full">
												₹ {payslipData.sal_ctc_pm}
											</td> */}
											<td className="border border-indigo-500/50 text-sm py-2 px-1 w-full text-end">
												Gross Salary
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 w-full text-end">
												₹ {payslipData.gross_salary}
											</td>
										</tr>
									</tbody>
								</table>
								<table className="w-full table-fixed">
									<tbody className="w-full">
										<tr className="payslip ">
											{/* <td className="border border-indigo-500/50 text-sm py-2 px-1 w-full">
												Salary (CTC)/ PA
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 w-full">
												₹ {payslipData.sal_ctc_pa}
											</td> */}
											<td className="border border-indigo-500/50 text-sm py-2 px-1 w-full text-end">
												Net Salary
											</td>
											<td className="border border-indigo-500/50 text-sm py-2 px-1 w-full text-end">
												₹ {payslipData.net_salary}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p className="text-lg font-bold mt-1 ">
								System generated payslip no signature required
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payslips;
